import { useEffect } from 'react';

const useScanner = <T>(
  cb: (data: T[]) => void,
  minimalLength: number = 0,
  options: T[] = [],
  cmpCb: (a: T, b: string) => boolean,
  ignoreValues: string[] = [],
  acceptOnly: string[] = []
) => {
  const onKeyUp = ({ key }: { key: string }) => {
    if (key === 'TVSatelliteToggle' || key === 'Unidentified') {
      if (window.scannerTimeout) {
        clearTimeout(window.scannerTimeout);
      }
      window.scannedText = '';
      window.scanning = true;
      window.scannerTimeout = setTimeout(() => {
        window.scanning = false;
        if (window.scannerTimeout) {
          clearTimeout(window.scannerTimeout);
        }
      }, 5000);
    }
  };

  const onKeyPress = ({ key }: { key: string }) => {
    if (window.scanning) {
      window.scannedText += key;
      if (window.scannerTimeout) {
        clearTimeout(window.scannerTimeout);
      }
      window.scannerTimeout = setTimeout(() => {
        window.scanning = false;
        if (window.scannerTimeout) {
          window.scannerTimeout = null;
        }

        if (
          window.scannedText?.length >= minimalLength &&
          !ignoreValues.includes(window.scannedText) &&
          (acceptOnly.length === 0 || acceptOnly.includes(window.scannedText))
        ) {
          cb(options.filter((option) => cmpCb(option, window.scannedText)));
        }
        window.scannedText = '';
      }, 50);
    }
  };
  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('keypress', onKeyPress);
    return () => {
      window.removeEventListener('keyup', onKeyUp);
      window.removeEventListener('keypress', onKeyPress);
    };
  }, []);
};

export default useScanner;
